<template>
  <div v-if="Event">
    <HeroText :title="Event.eventTitle" :subtitle="Event.eventSubtitle" />
    <template v-if="Event.eventState === 'coming'">
      <div class="container ">
        <div
          v-for="(row, i) in Event.eventComingSoon"
          :key="i"
          :class="{'text-right': row.alignRight}"
        >
          <h3 class="uppercase mb-4">{{row.title}}</h3>
          <MarkdownBlock class="coming-text" :text="row.text" />
        </div>
      </div>
    </template>
    <template v-else-if="Event.eventState === 'live' || Event.eventState === 'post'">
      <div class="wrapper flex flex-col justify-center">
        <div
          ref="video"
          class="video"
          :style="computedStyle"
        >
          <VideoPlayer v-if="Event.eventState === 'post'" :video="Event.eventEmbedUrl" />
          <LivePlayer v-else />
        </div>
        <div
          ref="captions"
          class="captions md:flex md:justify-between lg:justify-start pt-4"
          :style="{width: computedStyle.width}"
        >
          <div
            :class="{'lg:mr-12': i < Event.eventInfo.length -1}"
            v-for="({title, description}, i) in Event.eventInfo"
            :key="i"
          >
            <span class="label">{{title}}</span>
            <p class="description">{{description}}</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { Viewport, MarkdownBlock } from '@monogrid/vue-lib'
import EventQuery from '@/graphql/GetEvent.gql'
import VideoPlayer from '@/molecules/VideoPlayer'
import HeroText from '@/atoms/HeroText'
import LivePlayer from '@/atoms/LivePlayer'

export default {
  name: 'EventPage',
  mixins: [Viewport],
  components: { HeroText, VideoPlayer, MarkdownBlock, LivePlayer },
  apollo: {
    Event: {
      query: EventQuery,
      variables () {
        return {
          status: 'PUBLISHED',
          id: '757eb7dc-6ba0-4905-8bd1-5a19a03e1fa2',
          variants: [this.$language.current === 'en' ? 'base' : this.$language.current]
        }
      }
    }
  },
  data () {
    return {
      computedStyle: {}
    }
  },
  mounted () {
    if (this.$root.userIsLogged) {
      this.$root.sendEvent({
        event: 'event_page_view',
        category: 'live-player',
        action: `${this.$root.userIsLogged}`,
        label: 'Event page view'
      })
    }
  },
  watch: {
    Event: {
      immediate: true,
      handler (value) {
        if (value) {
          this.$root.preloader.visible = false
          this.$nextTick(this.onResize)
        }
      }
    },
    viewPort () {
      this.onResize()
    }
  },
  methods: {
    async onResize () {
      if (!this.$refs.captions) return
      const header = 80
      const footer = 80
      const captionsH = this.$refs.captions.getBoundingClientRect().height
      const safeAreaW = this.viewPort.width - 32
      const safeAreaH = this.viewPort.height - captionsH - header - footer
      const width = (safeAreaH * 16) / 9

      const safeWidth = Math.min(width, safeAreaW)
      const safeHeight = (safeWidth * safeAreaH) / width

      this.computedStyle = {
        width: `${~~safeWidth}px`,
        height: `${~~safeHeight}px`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  font-weight: 500;
}

.coming-text {
  p {
    margin-bottom: rem(12px);
  }
}

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.video,
.captions {
  margin: 0 auto;
}

.description {
  color: $c-mid-grey;
  font-size: rem(20px);
}

@media (orientation: landscape) and (max-height: rem(600px)) {
  .label {
    font-size: rem(14px);
  }

  .description {
    font-size: rem(12px);
  }

  .captions {
    display: flex;

    width: 90% !important;

    flex-direction: row;
    justify-content: space-between;

    > * {
      width: 30%;
    }

    > *:nth-child(2) {
      text-align: center;
    }

    > *:last-child {
      text-align: right;
    }
  }
}
</style>
