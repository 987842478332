<template>
  <div class="player-container">
    <div id='wowza_player' :class="{visible}"/>
    <transition name="fade">
      <div class="loader" v-if="!visible" />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'LivePlayer',
  data () {
    return {
      visible: false
    }
  },
  mounted () {
    if (!document.getElementById('player_embed')) {
      const firstScriptTag = document.getElementsByTagName('script')[0]
      const tag = document.createElement('script')

      tag.id = 'player_embed'
      tag.src = '//player.cloud.wowza.com/hosted/61z1j9lv/wowza.js'
      tag.addEventListener('load', this.onLoad)
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    } else if (!window.WowzaPlayer) {
      document.getElementById('player_embed').addEventListener('load', this.onLoad)
    } else {
      this.onLoad()
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)

    if (document.getElementById('player_embed')) {
      document.getElementById('player_embed').removeEventListener('load', this.onLoad)
    }
    if (window.WowzaPlayer && window.WowzaPlayer.get('wowza_player')) {
      window.WowzaPlayer.get('wowza_player').destroy()
    }
    this.$root.sendEvent({
      event: 'close',
      category: 'live-player',
      action: this.$root.userIsLogged,
      label: 'User change page'
    })
  },
  methods: {
    onLoad () {
      dispatchEvent(new Event('load'))
      this.interval = setInterval(() => {
        const player = window.WowzaPlayer ? window.WowzaPlayer.get('wowza_player') : null
        if (player) {
          player.onLoad(() => {
            this.visible = true

            this.$root.sendEvent({
              event: 'loaded',
              category: 'live-player',
              action: `${this.$root.userIsLogged}`,
              label: 'Player loaded'
            })
          })

          player.onPlay((event) => {
            this.$root.sendEvent({
              event: 'play',
              category: 'live-player',
              action: `${this.$root.userIsLogged}`,
              label: 'Time: ' + event.time
            })
          })
          player.onResume((event) => {
            this.$root.sendEvent({
              event: 'resume',
              category: 'live-player',
              action: `${this.$root.userIsLogged}`,
              label: 'Time: ' + event.time
            })
          })
          player.onStop((event) => {
            this.$root.sendEvent({
              event: 'stop',
              category: 'live-player',
              action: `${this.$root.userIsLogged}`,
              label: 'Time: ' + event.time
            })
          })
          player.onPause((event) => {
            this.$root.sendEvent({
              event: 'pause',
              category: 'live-player',
              action: `${this.$root.userIsLogged}`,
              label: 'Time: ' + event.time
            })
          })
          player.onError(() => {
            this.$root.sendEvent({
              event: 'error',
              category: 'live-player',
              action: `${this.$root.userIsLogged}`
            })
          })

          clearInterval(this.interval)
        }
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
.player-container {
  position: relative;

  width: 100%;
  height: 100%;

}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;

  width: rem(80px);
  height: rem(80px);

  transform: translate(-50%, -50%);

  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border: rem(4px) solid $c-red;
    border-radius: 50%;

    animation: ripple-loader 1s ease-out infinite;
    background: $c-red;

    content: '';
  }

  &::after {
    animation-delay: -0.5s;
  }
}

@keyframes ripple-loader {
  0% {
    opacity: 1;
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

// stylelint-disable-next-line
#wowza_player {
  width: 100%;
  height: 0;

  padding: 0 0 56.25%;

  opacity: 0;
  transition: opacity 0.5s 0.3s;
  // stylelint-disable-next-line
  &.visible {
    opacity: 1;
  }
}
</style>
